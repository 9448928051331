<template>
    <div>
        <div class="facilityBox">
            <div style="display: flex;align-items:center;justify-content: space-between;">
                <el-tabs v-model="rateType">
                    <el-tab-pane label="服务商" name="1"></el-tab-pane>
                    <el-tab-pane label="代理商" name="2"></el-tab-pane>
                </el-tabs>
                <el-button :type="optionstatus == 20?'warning':'primary'"  @click="toCooperateRate" style="margin-right: 20px;"
                    :disabled="optionstatus != 20">
                    {{ optionstatus == 20 ? '正式合作' : (optionstatus == 100 ? '已合作' : '审核中') }}
                </el-button>
            </div>

            <el-table :data="rateType == '1' ? rateData : rateDataAgent"
                :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
                <el-table-column type="index" align="center" label="序号" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="发票-业务-场景">
                    <template slot-scope="scope">
                        <span v-if="scope.row.TaskSceneName">
                            {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                                scope.row.TaskSceneName
                            }}</span>
                        <span v-else>
                            {{ scope.row.InvoiceTypeName }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="费率" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="技术服务费率" show-overflow-tooltip v-if="rateType == '1'">
                    <template slot-scope="scope">
                        {{ scope.row.TecRate ? scope.row.TecRate + '%' : scope.row.TecRate }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="watchRecordData(scope.row)"
                            icon="el-icon-tickets">查看历史记录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 查看费率历史记录 -->
        <el-dialog title="历史记录" :visible.sync="rateRecordDialog" width="40%" v-if="rateRecordDialog" class="rate-dialog">
            <div style="text-align: center;font-weight: bold;margin-bottom: 10px;">发票-业务-场景： <span
                    v-if="currentItem.TaskSceneName">
                    {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} / {{
                        currentItem.TaskSceneName
                    }}</span>
                <span v-else>
                    {{ currentItem.InvoiceTypeName }}</span>
            </div>
            <el-table :data="rateRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
                <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.Rate ? scope.row.Rate + '%' : '' }}</template>
                </el-table-column>
                <el-table-column align="center" prop="TecRate" label="技术服务费费率" show-overflow-tooltip v-if="rateType == '1'">
                    <template slot-scope="scope">{{ scope.row.TecRate ? scope.row.TecRate + '%' : '' }}</template>
                </el-table-column>
                <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <div style="margin-top:10px">
                <el-pagination background class="pagination" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { GetSupplierRate, GetRateRecords, CooperateRate, GetRateOptionStatus } from "@/api/newRate";
import { _getUserId } from "@/utils/storage";
export default {
    data() {
        return {
            rateType: '1',
            rateData: [],
            rateDataAgent: [],
            currentItem: '',
            loading: false,
            rateRecordDialog: false,
            rateRecord: [],
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            optionstatus: null
        }
    },
    created() {
        this.getSupplierRate()
        this.getRateOptionStatus()
    },
    methods: {
        // 获取费率
        getSupplierRate() {
            this.loading = true
            GetSupplierRate({ userid: parseInt(_getUserId()) / 5 }).then(res => {
                let ratelist = []
                let eInvoiceTypeListAgent = []
                res.data.SupplierRate.forEach(item => {
                    item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
                    item.TecRate = (item.TecRate == 0 || item.TecRate == null) ? 0.3 : item.TecRate
                    ratelist.push(item)
                })
                res.data.AgentRate.forEach(item => {
                    item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
                    eInvoiceTypeListAgent.push(item)
                })
                this.rateData = ratelist
                this.rateDataAgent = eInvoiceTypeListAgent
            }).finally(() => {
                this.loading = false
            })
        },
        // 查看历史记录
        watchRecordData(item) {
            this.currentItem = item
            this.getRateRecords()
        },
        getRateRecords() {
            this.loading = true
            GetRateRecords({
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                rateID: this.currentItem.RateID
            }).then(res => {
                this.rateRecord = res.data.DataList
                this.pagination.total = res.data.TotalCount;
                this.rateRecordDialog = true
            }).finally(() => {
                this.loading = false
            })
        },
        handleCurrentChange(e) {
            this.pagination.page = e;
            this.getRateRecords();
        },
        // 查询合作状态
        getRateOptionStatus() {
            GetRateOptionStatus({ userid: parseInt(_getUserId()) / 5 }).then(res => {
                this.optionstatus = res.data.Status
            })
        },
        // 正式合作
        toCooperateRate() {
            this.$confirm('确定要进行合作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                CooperateRate({
                    userID: parseInt(_getUserId()) / 5
                }).then(() => {
                    this.$message.success('请求成功!');
                    this.getSupplierRate()
                    this.getRateOptionStatus()
                    this.rateType = '1'
                }).finally(() => {
                    this.loading = false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    }
}
</script>